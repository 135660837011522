.listestation {


    .background {
        height: 30vh;
        background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url('../../assets/header_bck_compare.webp');
    };

    .textbox{
        display: flex;
        //height: 100%;
        justify-content: center;
        align-items: center; 
    }

    .textbox h1 {
        font-size: 40px;
        margin: 16px;
        padding-top: 16px;

    };


    background: $fond-accentue;

    .entePage h2{
        color: $typo-claire;
        ;  
    };

    .entePage span{
        color: $couleur-selection;  
    };


    .filtre-btn {
        display: inline-block;
        text-decoration: none;
        color: $fond-clair;
        border: 1px solid $fond-clair;
        padding: 5px 5px;
        font-size: 13px;
        background: $typo-claire;
        position: relative;
        cursor: pointer;

    & :hover {
        border: 1px solid $couleur-selection;
        background: $couleur-selection;
        transition: 1s;
        color: $fond-clair;
    }
    };

    .section-list-station{
        color: $typo-foncee;
        display: flex;
        justify-content: center;
        align-items: center;  
    }
    .detail_lststation{
        width: 1140px;
    }
    .main-filters {
        max-width: 1140px;
        margin: 0 auto;
        width: 100%;
    }

    .entePage {
        //background: #fff;
        margin: 16px;
    };

    .grilleStation {
        display: flex;
        justify-content: center;
        align-items: flex-start;  
        //max-width: 1140px;
    };

    

    .FiltreStation {
        //width: 280px;
        min-width: 300px;
        margin-top: 20px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 3%;
        list-style: none;
        background: $fond-clair;
    };

    .enteteFilterMenu{
        height: 30px;
    }

    .cancelFilterButton button{
        margin: 4px;
        padding: 10px;    
        border: none;
        background: $typo-claire;
        color: inherit;
        color: $fond-clair;
        &:hover{
            background: $couleur-selection;
            transition: 1s;
            color: $fond-clair;
    } 
    };


    .filterType{
        //min-width: 180px;;
        justify-content: center;
        border-bottom: $typo-claire; 
        box-sizing: border-box;
        border-bottom: 1px solid #D8DADF;
    };

    .infinite-scroll-component  {
        display: flex;
        flex-flow: row wrap;
              
    };

    

    .headFilter{
        color: $typo-claire;
        display: flex;
        height: 56px;
        align-items: center;  
        user-select: none; 
    };

    details summary::before { 
        content:"+"; 
        display: inline-block;
        margin-left: 3px;
        margin-right: 5px;
        transition: transform 0.4s ease-out;
      }
      
      details[open] summary::before {
          transform: rotate(45deg);
      }

    .button{
        margin: 2px;
        padding: 2 px;
    };


    .grilleStation h3 {
       // grid-auto-rows: min(20px);
        padding: 10px 5px;
    };

    
    .region-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        height: 250px;
    };

    .region-container li {
        margin-left: 5px;
        padding: 5px;
    };

    .region-container li label {
        padding-left: 5px
    };


    .filter-checkbox{
        overflow-y: auto;
        max-height: 300px;
    };

    .filter-checkbox-ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;    
    };
    
    .filter-checkbox-ul li {
        margin-left: 5px;
        padding: 3px; 
    };
    
    .filter-checkbox-ul li label {
        padding-left: 5px
    };


    .groupe_Range {
        display: flex;
        flex-direction: column;
        background: $fond-clair;
        justify-content: center;
        border-bottom: $fond-clair; 
        box-sizing: border-box;
        border-bottom: 1px solid #D8DADF;
    };

   p{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

   };


    .label-container li {
        list-style: none;
        margin-left: 5px;
        padding: 5px; 
    };

    .label-container li label {
        padding-left: 5px
    };

    .multi-range-slider {
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 5px;
        border: none;
        box-shadow: none;
        border-radius: none;
    };

    

    @media screen and (max-width: 725px) {
        .grilleStation {
            display: flex;
           flex-flow:wrap;
            max-width: 95vw;  
        };
       
       
        .listeStationGrid{
            display: flex;
            flex-flow: column

        };
    }
   
   
   
    @media screen and (max-width: 600px) {
        .textbox h1 {
            font-size: 26px;
        }
        
        .textbox p {
            font-size: 11px;
        }

        .entePage {
            margin: 32px;
        };

        .grilleStation {
            justify-content: center;        
        };

        .FiltreStation {
            width: 330px;
        };

        .infinite-scroll-component  {
            justify-content: center;    
        };


       
       
        .filter-button-mobile {
            display: block;
        }

        .main-filters {
            .FiltreStation {
                display: none;
            }
            
            .listeStationGrid {
                width: 100%;
            }
        }

        .filter-modal-content {
            .grilleStation {
                display: block;
                width: 100%;
                margin: 0;
                padding: 10px;

                .FiltreStation {
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }
    }

    @media screen and (min-width: 601px) {
        .filter-modal {
            display: none !important;
        }
    }

    multi-range-slider * {
        box-sizing: border-box;
        padding: 10px;
        margin: 0px;
    }
    .multi-range-slider {
        display: flex;
        position: relative;
        //border: solid 1px gray;
        //border-radius: 10px;
        padding: 20px 10px;
        //box-shadow: 1px 1px 4px black;
        flex-direction: column;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
    }
    .multi-range-slider .bar {
        display: flex;
    }
    .multi-range-slider .bar-left {
        width: 25%;
        background-color: #f0f0f0;
        border-radius: 10px 0px 0px 10px;
        box-shadow: inset 0px 0px 5px black;
        padding: 4px 0px;
    }
    .multi-range-slider .bar-right {
        width: 25%;
        background-color: $fond-clair;
        border-radius: 0px 10px 10px 0px;
        box-shadow: inset 0px 0px 5px black;
    }
    .multi-range-slider .bar-inner {
        background-color: $color-6;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: space-between;
        position: relative;
        border: solid 1px black;
        justify-content: space-between;
        box-shadow: inset 0px 0px 5px black;
    }
    .multi-range-slider .bar-inner-left {
        width: 50%;
    }
    .multi-range-slider .bar-inner-right {
        width: 50%;
    }
    .multi-range-slider .thumb {
        background-color: white;
        position: relative;
        z-index: 1;
        cursor: pointer;
    }
    .multi-range-slider .thumb::before {
        content: '';
        background-color: inherit;
        position: absolute;
        width: 20px;
        height: 20px;
        border: solid 1px black;
        box-shadow: 0px 0px 3px black, inset 0px 0px 5px gray;
        border-radius: 50%;
        z-index: 1;
        margin: -8px -12px;
        cursor: pointer;
    }
    .multi-range-slider .input-type-range:focus + .thumb::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -7px;
        width: 11px;
        height: 11px;
        z-index: 2;
        border-radius: 50%;
        border: dotted 1px black;
        box-shadow: 0px 0px 5px white, inset 0px 0px 10px black;
    }
    .multi-range-slider .caption {
        position: absolute;
        bottom: 35px;
        width: 2px;
        height: 2px;
        left: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        display: none;
    }
    .multi-range-slider .thumb .caption * {
        position: absolute;
        min-width: 30px;
        height: 30px;
        font-size: 75%;
        text-align: center;
        line-height: 30px;
        background-color: $couleur-selection;
        border-radius: 15px;
        color: white;
        box-shadow: 0px 0px 5px black;
        padding: 0px 5px;
        white-space: nowrap;
    }
    .multi-range-slider .thumb:active .caption {
        display: flex;
    }
    .multi-range-slider .input-type-range:focus + .thumb .caption {
        display: flex;
    }
    .multi-range-slider .input-type-range {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        opacity: 0;
        pointer-events: none;
    }
    
    .multi-range-slider .ruler {
        margin: 10px 0px -5px 0px;
        display: flex;
        /* display: none; */
        overflow: hidden;
    }
    .multi-range-slider .ruler .ruler-rule {
        border-left: solid 1px;
        border-bottom: solid 1px;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 5px 0px;
    }
    .multi-range-slider .ruler .ruler-rule:last-child {
        border-right: solid 1px;
    }
    
    .multi-range-slider .ruler .ruler-sub-rule {
        border-left: solid 1px;
        /* border-bottom: solid 1px; */
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 3px 0px;
        bottom: 0px;
        margin-bottom: -5px;
    }
    .multi-range-slider .ruler .ruler-sub-rule:first-child {
        border-left: none;
    }
    
    .multi-range-slider .labels {
        display: flex;
        justify-content: space-between;
        padding: 0px;
        margin-top: 10px;
        margin-bottom: -20px;
        /* display: none; */
    }
    .multi-range-slider .label {
        font-size: 80%;
        display: flex;
        width: 1px;
        justify-content: center;
    }
    .multi-range-slider .label:first-child {
        justify-content: start;
    }
    .multi-range-slider .label:last-child {
        justify-content: end;
    }
    .multi-range-slider.zero-ranage-margin .thumb-left {
        right: 12px;
    }
    .multi-range-slider.zero-ranage-margin .thumb-right {
        left: 8px;
    }
}


;

.filter-button-mobile {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: $couleur-selection;
    color: white;
    border: none;
    border-radius: 5px;
    z-index: 100;
    cursor: pointer;
}

.filter-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    
    &.open {
        display: block;
    }
}

.filter-modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    padding-top: 60px;
}

.filter-modal-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    z-index: 1001;
    border-bottom: 1px solid #D8DADF;
}

.close-modal {
    position: static;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .filter-button-mobile {
        display: block;
    }

    .desktop-filters {
        display: none;
    }

    .filter-modal-content {
        .grilleStation {
            display: block;
            width: 100%;
            margin: 0;
            padding: 10px;

            .FiltreStation {
                width: 100%;
                margin-top: 40px; // Pour laisser de l'espace pour le bouton de fermeture
            }
        }
    }
}

@media screen and (min-width: 601px) {
    .filter-modal {
        display: none !important;
    }
}
