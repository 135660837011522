nav{
    position: fixed; 
    padding: 8px 16px;
    height: 100px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    
  //  overflow: hidden;
}

.nav-bg{
    //backdrop-filter: blur(25px);
    background: $color-4;
};

.sous-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
};

.nav-links{
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search-icon {
        width: 24px;
        height: 24px;
        margin-left: 15px;
        cursor: pointer;
        
        img {
            width: 100%;
            height: 100%;
            transition: transform 0.2s ease;
            filter: brightness(0) invert(1);
        }

        &:hover img {
            transform: scale(1.1);
        }
    }
}

.nav img{
    width: 75px ;
    height: 75px;
};

.menu-btn {
    display: none;
    margin-right: 10px; 
};

.menu-btn img {
    padding: 12px;  /* Pour créer une zone tactile suffisante */
    width: 24px;    /* Taille de l'icône elle-même */
    height: 24px;
    min-height: 44px; /* Zone tactile minimale */
    min-width: 44px;
};

.nav-links ul li{
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
    &::after{
        content:'';
        width: 0%;
        height: 2px;
        background: #f44336;
        display: block;
        margin: auto;
        transition: 0.5s;
    }
    &:hover::after{
        width: 100%;
       }
}

.nav-links ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .menu-btn {
        display: flex;
        flex-direction: column;
    };
    .nav-links {
        transform: translateY(-100%); 
        position: absolute; 
        top : 0;
        left : 0;  
       // transition: all 1200ms cubic-bezier(0.3, 0.96, 0.59, 0.95);
   }

       .nav-links.active {
        transform: translateY(0);
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-4;
        justify-content:flex-start;
        align-items: center;
        z-index: 60;
        padding-bottom: 10px;
} 

    .nav-links ul{
        transform: translateY(0);
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-4;
        justify-content:flex-start;
        align-items: center;
    }

    .nav-links button {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .nav-links button img {
    width: 25px ;
    height: 25px;    
     }

    .nav-links {
        .search-icon {
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }

 

    }

.navbar {
  // ... existing code ...
  
  &__search {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    
    &-icon {
      cursor: pointer;
      font-size: 1.2rem;
      color: var(--text-primary);
      transition: color 0.2s ease;
      
      &:hover {
        color: var(--primary);
      }
    }
  }
}

.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-4, 0.95);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  
  &__close {
    position: absolute;
    top: -80px;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    
    img {
      width: 24px;
      height: 24px;
      filter: brightness(0) invert(1);
      transition: transform 0.2s ease;
      
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  
  &__content {
    width: 100%;
    max-width: 600px;
    margin-top: 120px;
    padding: 20px 40px;
    position: relative;
  }
  
  &__input-container {
    width: 100%;
    margin-top: 20px;
  }
  
  &__input {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: white;
    
    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
    
    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__results {
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
    }
    
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
    }
  }

  &__result-item {
    display: block;
    padding: 15px;
    margin: 5px 0;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__result-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__result-type {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
    padding: 2px 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
  }

  &__no-results {
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 1.1rem;
    font-style: italic;
  }
}

// Animation pour la modale
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-modal {
  animation: fadeIn 0.3s ease-out;
}



